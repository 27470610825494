import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { AboutUs } from './pages/AboutUs';
import { Cennik } from './pages/Cennik';
import { Team } from './pages/Team'; 
import { Header } from './components/Header';
import { Footer } from './components/Footer';

function App() {
  
  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault(); 
    };
    
    document.addEventListener('contextmenu', handleContextMenu); 
    
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu); 
    };
  }, []); 

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/onas" element={<AboutUs />} />
        <Route path="/cennik" element={<Cennik />} />
        <Route path="/team" element={<Team />} /> 
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

