import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faServer,
  faHeadset,
  faLock,
  faWallet,
  faCodeBranch,
  faBolt,
  faRocket,
  faShieldAlt,
} from '@fortawesome/free-solid-svg-icons';

export const AboutUs: FC = () => {
  return (
    <Box sx={{ py: 8, px: { xs: 2, md: 4 }, bgcolor: '#f4f7fb' }}>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <Typography
          variant="h4"
          align="center"
          fontWeight="bold"
          gutterBottom
          sx={{ color: '#333' }}
        >
          O nas{' '}
          <Typography
            component="span"
            sx={{ color: '#1e90ff', fontWeight: 'bold', fontSize: '2.2rem' }}
          >
            BlackHost.pl
          </Typography>
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          align="center"
          sx={{ maxWidth: '800px', mx: 'auto', mb: 4, fontSize: '1rem' }}
        >
          BlackHost.pl to polska firma oferująca różnorodne usługi hostingowe, w
          tym hosting dla botów Discord. Jesteśmy zaangażowani w dostarczanie
          najlepszych rozwiązań hostingowych dla naszych klientów, z naciskiem
          na wydajność, niezawodność i prostotę obsługi.
        </Typography>
      </motion.div>

      {/* Key Benefits */}
      <Grid container spacing={6} justifyContent="center" sx={{ mt: 6 }}>
        <Grid item xs={12} md={4}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.1 }}
          >
            <Box sx={{ textAlign: 'center', ':hover': { transform: 'scale(1.05)', transition: 'transform 0.3s ease-in-out' } }}>
              <FontAwesomeIcon icon={faHeadset} size="3x" color="#1e90ff" />
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                Wsparcie techniczne
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Oferujemy wsparcie techniczne w języku polskim, co jest dużym
                atutem dla lokalnych użytkowników.
              </Typography>
            </Box>
          </motion.div>
        </Grid>

        <Grid item xs={12} md={4}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            <Box sx={{ textAlign: 'center', ':hover': { transform: 'scale(1.05)', transition: 'transform 0.3s ease-in-out' } }}>
              <FontAwesomeIcon icon={faBolt} size="3x" color="#1e90ff" />
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                Wydajność i niezawodność
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Nasze serwery są zoptymalizowane pod kątem wydajności i
                niezawodności, co zapewnia płynne działanie botów Discord.
              </Typography>
            </Box>
          </motion.div>
        </Grid>

        <Grid item xs={12} md={4}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.3 }}
          >
            <Box sx={{ textAlign: 'center', ':hover': { transform: 'scale(1.05)', transition: 'transform 0.3s ease-in-out' } }}>
              <FontAwesomeIcon icon={faServer} size="3x" color="#1e90ff" />
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                Elastyczna oferta
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Oferujemy różne pakiety hostingowe, takie jak VPS i serwery
                dedykowane, które można dostosować do potrzeb.
              </Typography>
            </Box>
          </motion.div>
        </Grid>

        <Grid item xs={12} md={4}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.4 }}
          >
            <Box sx={{ textAlign: 'center', ':hover': { transform: 'scale(1.05)', transition: 'transform 0.3s ease-in-out' } }}>
              <FontAwesomeIcon icon={faWallet} size="3x" color="#1e90ff" />
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                Konkurencyjne ceny
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Oferujemy atrakcyjne ceny oraz promocje dla nowych
                użytkowników, które można dopasować do budżetu.
              </Typography>
            </Box>
          </motion.div>
        </Grid>

        <Grid item xs={12} md={4}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <Box sx={{ textAlign: 'center', ':hover': { transform: 'scale(1.05)', transition: 'transform 0.3s ease-in-out' } }}>
              <FontAwesomeIcon icon={faLock} size="3x" color="#1e90ff" />
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                Bezpieczeństwo
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Dbamy o bezpieczeństwo danych, oferując mechanizmy ochrony,
                takie jak kopie zapasowe, firewalle i zabezpieczenia DDoS.
              </Typography>
            </Box>
          </motion.div>
        </Grid>

        <Grid item xs={12} md={4}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.6 }}
          >
            <Box sx={{ textAlign: 'center', ':hover': { transform: 'scale(1.05)', transition: 'transform 0.3s ease-in-out' } }}>
              <FontAwesomeIcon icon={faCodeBranch} size="3x" color="#1e90ff" />
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                Współpraca z twórcami botów
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Oferujemy specjalistyczne wsparcie dla twórców botów,
                pomagając w ich uruchamianiu i zarządzaniu.
              </Typography>
            </Box>
          </motion.div>
        </Grid>
      </Grid>

      <Box sx={{ mt: 8 }}>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <Typography variant="h4" align="center" fontWeight="bold" gutterBottom>
            Dlaczego warto wybrać{' '}
            <Typography
              component="span"
              sx={{ color: '#1e90ff', fontWeight: 'bold', fontSize: '2rem' }}
            >
              BlackHost.pl
            </Typography>{' '}
            do hostingu botów Discord?
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            align="center"
            sx={{ maxWidth: '800px', mx: 'auto', mb: 4 }}
          >
            Nasza oferta obejmuje szybkie serwery, doskonałe wsparcie techniczne
            oraz elastyczne opcje płatności, które idealnie pasują do potrzeb
            twórców botów Discord.
          </Typography>
        </motion.div>

        <Grid container spacing={6} justifyContent="center" sx={{ mt: 6 }}>
          <Grid item xs={12} md={4}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.2 }}
            >
              <Box sx={{ textAlign: 'center', ':hover': { transform: 'scale(1.05)', transition: 'transform 0.3s ease-in-out' } }}>
                <FontAwesomeIcon icon={faRocket} size="3x" color="#1e90ff" />
                <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                  Szybka konfiguracja
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 1 }}
                >
                  Nasze serwery są gotowe do działania od razu po zamówieniu, co
                  pozwala na szybkie uruchomienie bota.
                </Typography>
              </Box>
            </motion.div>
          </Grid>

          <Grid item xs={12} md={4}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.4 }}
            >
              <Box sx={{ textAlign: 'center', ':hover': { transform: 'scale(1.05)', transition: 'transform 0.3s ease-in-out' } }}>
                <FontAwesomeIcon icon={faShieldAlt} size="3x" color="#1e90ff" />
                <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                  Stabilność działania
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 1 }}
                >
                  Nasza infrastruktura gwarantuje wysoką dostępność i niezawodność
                  w obsłudze botów 24/7.
                </Typography>
              </Box>
            </motion.div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
