import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMemory, faMicrochip, faHardDrive, faCloud } from '@fortawesome/free-solid-svg-icons';
import StarsIcon from '@mui/icons-material/Stars';
import { motion } from 'framer-motion';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const subtleNeonEffect = {
  boxShadow: '0 0 10px #1e90ff, 0 0 20px #1e90ff, 0 0 30px #1e90ff',
};

export const Offer: FC = () => {
  const offers = [
    { title: 'Trial (24h)', price: 'FREE', features: ['10% vCPU', '128MB RAM', '512 MB DISK', '0 BACKUPS'] },
    { title: 'Small', price: '4.99 PLN', features: ['50% vCPU', '1GB RAM', '1 GB DISK', '3 BACKUPS'] },
    { title: 'Medium', price: '9.99 PLN', features: ['75% vCPU', '1,5GB RAM', '2 GB DISK', '3 BACKUPS'], popular: true },
  ];

  const getIcon = (feature: string) => {
    if (feature.includes('RAM')) return <FontAwesomeIcon icon={faMemory} color="#1e90ff" />;
    if (feature.includes('vCPU')) return <FontAwesomeIcon icon={faMicrochip} color="#1e90ff" />;
    if (feature.includes('DISK')) return <FontAwesomeIcon icon={faHardDrive} color="#1e90ff" />;
    if (feature.includes('BACKUPS')) return <FontAwesomeIcon icon={faCloud} color="#1e90ff" />;
    return null;
  };

  return (
    <Box sx={{ bgcolor: 'white', py: 10 }}>
      <Box maxWidth="lg" mx="auto">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography
            variant="h4"
            align="center"
            fontWeight="bold"
            color="text.primary"
            gutterBottom
            sx={{ mb: 6 }}
          >
            Nasza{' '}
            <Typography component="span" color="primary" sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: '2rem' }}>
              oferta
            </Typography>
          </Typography>
        </motion.div>
        <Grid container spacing={6} justifyContent="center">
          {offers.map((offer, idx) => (
            <Grid item xs={12} sm={5} md={4} key={idx}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: idx * 0.2 }}
              >
                <Card
                  sx={{
                    borderRadius: '16px',
                    boxShadow: offer.popular ? subtleNeonEffect.boxShadow : '0 4px 10px rgba(0, 0, 0, 0.1)',
                    transform: offer.popular ? 'scale(1.05)' : 'scale(1)',
                    transition: 'transform 0.3s ease-in-out',
                    position: 'relative',
                    minHeight: '400px',
                    padding: '20px',
                  }}
                >
                  {offer.popular && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '0.1px',
                        right: '15px',
                        bgcolor: '#1e90ff',
                        color: 'white',
                        px: 2.5,
                        py: 1,
                        borderRadius: '0 0 8px 8px',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      <StarsIcon sx={{ mr: 0.5, fontSize: '18px' }} />
                      <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                        Popularne
                      </Typography>
                    </Box>
                  )}
                  <CardContent>
                    <Typography variant="h6" color="text.primary" align="center" gutterBottom sx={{ mb: 2 }}>
                      {offer.title}
                    </Typography>
                    <Typography variant="h5" color="primary" align="center" gutterBottom sx={{ mb: 2 }}>
                      {offer.price}
                    </Typography>
                    <Box my={2}>
                      <ul style={{ padding: 0, listStyleType: 'none' }}>
                        {offer.features.map((feature, i) => (
                          <li key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '12px' }}>
                            {getIcon(feature)}
                            <Typography variant="body2" color="text.secondary" align="center" sx={{ ml: 1 }}>
                              {feature}
                            </Typography>
                            {/* Tooltip for RAM */}
                            {feature.includes('RAM') && (
                              <Tooltip title="RAM to 512 MB + SWAP" arrow>
                                <Box
                                  sx={{
                                    ml: 1,
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50%',
                                    backgroundColor: '#e0e0e0',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <HelpOutlineIcon sx={{ fontSize: '16px', color: 'gray' }} />
                                </Box>
                              </Tooltip>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Button variant="contained" color="primary" sx={{ borderRadius: '30px', mt: 3 }} href="/cennik">
                        Zakup {offer.title}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
