import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import logo from '../img/logo.png';

export const Footer: FC = () => {
  return (
    <Box
      sx={{
        bgcolor: 'rgba(255, 255, 255, 0.3)',
        backdropFilter: 'blur(15px)',
        py: { xs: 2, md: 3 },
        px: { xs: 2, md: 3 },
        mt: 6,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
      }}
    >
      <Grid container spacing={4} justifyContent="space-between" alignItems="center">
        {/* Left Section: Logo and Description */}
        <Grid item xs={12} md={4}>
          <Box display="flex" alignItems="center" mb={2}>
            <img src={logo} alt="BlackHost Logo" style={{ width: '40px', marginRight: '10px' }} />
            <Typography variant="h6" fontWeight="bold" sx={{ color: 'primary.main', fontSize: { xs: '1rem', md: '1.2rem' } }}>
              BlackHost
            </Typography>
          </Box>
          <Typography variant="body2" color="textSecondary" sx={{ lineHeight: 1.5 }}>
            BlackHost - Twój niezawodny partner w świecie hostingu. Gwarantujemy bezpieczeństwo, wydajność i wsparcie na każdym kroku.
          </Typography>
        </Grid>

        {/* Middle Section: Oferta */}
        <Grid item xs={6} md={2}>
          <Typography variant="h6" fontWeight="bold" sx={{ mb: 1, fontSize: '1rem', color: 'primary.dark' }}>
            Oferta
          </Typography>
          <Typography
            component="a"
            variant="body2"
            color="textSecondary"
            sx={{
              display: 'block',
              mb: 0.5,
              cursor: 'pointer',
              '&:hover': { color: 'primary.main', transition: 'color 0.3s ease' },
            }}
            href="/cennik"
          >
            Boty Discord
          </Typography>
          <Typography
            component="a"
            variant="body2"
            color="textSecondary"
            sx={{
              display: 'block',
              mb: 0.5,
              cursor: 'pointer',
              '&:hover': { color: 'primary.main', transition: 'color 0.3s ease' },
            }}
            href="/cennik"
          >
            Bazy Danych
          </Typography>
          <Typography
            component="a"
            variant="body2"
            color="textSecondary"
            sx={{
              display: 'block',
              cursor: 'pointer',
              '&:hover': { color: 'primary.main', transition: 'color 0.3s ease' },
            }}
            href="/cennik"
          >
            Serwery TeamSpeak3
          </Typography>
        </Grid>

        {/* Middle Section: Kontakt */}
        <Grid item xs={6} md={2}>
          <Typography variant="h6" fontWeight="bold" sx={{ mb: 1, fontSize: '1rem', color: 'primary.dark' }}>
            Kontakt
          </Typography>
          <Typography
            component="a"
            variant="body2"
            color="textSecondary"
            sx={{
              display: 'block',
              mb: 0.5,
              cursor: 'pointer',
              '&:hover': { color: 'primary.main', transition: 'color 0.3s ease' },
            }}
            href="https://dc.blackhost.pl"
            target="_blank"
          >
            Discord
          </Typography>
          <Typography
            component="a"
            variant="body2"
            color="textSecondary"
            sx={{
              display: 'block',
              cursor: 'pointer',
              '&:hover': { color: 'primary.main', transition: 'color 0.3s ease' },
            }}
            href="https://twitter.com"
            target="_blank"
          >
            Twitter
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{
              display: 'block',
              cursor: 'pointer',
              '&:hover': { color: 'primary.main', transition: 'color 0.3s ease' },
            }}
          >
            ‎ 
          </Typography>
        </Grid>

        <Grid item xs={12} md={3} display="flex" justifyContent="flex-end">
          <Box>
            <IconButton
              aria-label="Tiktok"
              href="https://www.tiktok.com/@blackhost.pl"
              target="_blank"
              sx={{
                color: 'primary.main',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  color: 'primary.dark',
                  transform: 'scale(1.1)',
                },
              }}
            >
              <i className="fa-brands fa-tiktok"></i>
            </IconButton>
            <IconButton
              aria-label="Twitter"
              href="https://www.twitter.com"
              target="_blank"
              sx={{
                color: 'primary.main',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  color: 'primary.dark',
                  transform: 'scale(1.1)',
                },
              }}
            >
              <i className="fa-brands fa-x-twitter"></i>
            </IconButton>
            <IconButton
              aria-label="Instagram"
              href="https://www.instagram.com/blackhost39/"
              target="_blank"
              sx={{
                color: 'primary.main',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  color: 'primary.dark',
                  transform: 'scale(1.1)',
                },
              }}
            >
              <i className="fa-brands fa-instagram"></i>
            </IconButton>
            <IconButton
              aria-label="Discord"
              href="https://dc.blackhost.pl"
              target="_blank"
              sx={{
                color: 'primary.main',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  color: 'primary.dark',
                  transform: 'scale(1.1)',
                },
              }}
            >
              <i className="fa-brands fa-discord"></i>
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.1)', mt: 3, pt: 2 }}>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ fontSize: '12px', letterSpacing: '0.05em' }}
        >
          © 2024 BlackHost. Wszystkie prawa zastrzeżone.
        </Typography>
      </Box>
    </Box>
  );
};
