import React from 'react';
import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown, faBriefcase, faCode } from '@fortawesome/free-solid-svg-icons'; 
import { motion } from 'framer-motion';


import HakeRR from '../avatars/HHakeRR.png';
import xdokinelek from '../avatars/xdokinelek.gif';
import vi_vio from '../avatars/vi_vio.png';
import Nertii from '../avatars/Nertii.gif';

const RoleTypography = styled(Typography)(({ color }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '4px',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: color || 'gray',
  textAlign: 'center',
}));

const RoleIcon = styled(FontAwesomeIcon)(({ color }) => ({
  marginRight: '8px',
  color: color || 'gray',
}));

const GlassCard = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  p: 3,
  borderRadius: '16px',
  backgroundColor: 'rgba(255, 255, 255, 0.8)', 
  backdropFilter: 'blur(12px)', 
  border: '1px solid rgba(255, 255, 255, 0.2)', 
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', 
  transition: 'transform 0.3s ease',
  '&:hover': { transform: 'scale(1.05)' }, 
  position: 'relative',
  overflow: 'visible', 
  minHeight: '260px', 
}));

const StyledAvatar = styled(Avatar)({
  width: 120,
  height: 120,
  borderRadius: '50%',
  border: '2px solid #e0e0e0',
  position: 'absolute',
  top: '-60px', 
  left: '50%',
  transform: 'translateX(-50%)',
});

const DescriptionText = styled(Typography)({
  marginTop: '8px',
  fontSize: '0.875rem',
  color: '#555',
  textAlign: 'center',
});

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

const avatarVariants = {
  hidden: { scale: 0 },
  visible: { scale: 1, transition: { delay: 0.3 } },
};

// Team component
export const Team: FC = () => {
  const projectLeaders = [
    {
      name: '!HHakeRR',
      avatar: HakeRR,
      role: 'Project Leader',
      roleColor: '#ffa700',
      icon: faCrown, 
      description: 'Odpowiedzialny za zarządzanie projektem i koordynację działań zespołu.',
    },
    // {
    //   name: '.xayers',
    //   avatar: xdokinelek,
    //   role: 'Project Leader',
    //   roleColor: '#ffa700',
    //   icon: faCrown, 
    //   description: 'Prowadzi działania zespołu oraz rozwój projektu.',
    // },
  ];

  const owners = [
    {
      name: 'vi_vio',
      avatar: vi_vio,
      role: 'Lead Developer',
      roleColor: '#4947cc',
      icon: faCode, 
      secondRole: 'Owner',
      secondIcon: faBriefcase, 
      description: 'Główny developer i właściciel, nadzoruje rozwój techniczny.',
    },
    {
      name: 'Nertii',
      avatar: Nertii,
      role: 'Owner',
      roleColor: '#e68a00',
      icon: faBriefcase, 
      description: 'Właściciel, odpowiedzialny za strategię i zarządzanie.',
    },
  ];

  return (
    <Box sx={{ py: 8, px: { xs: 2, md: 4 }, bgcolor: '#f7f9fc' }}>
        <Typography variant="h4" align="center" fontWeight="bold" gutterBottom>
          Zespół <Typography component="span" color="primary" sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: '2rem' }}>BlackHost.pl</Typography>
        </Typography>

      <Grid container spacing={6} justifyContent="center" sx={{ mt: 6 }}>
        {projectLeaders.map((leader, idx) => (
          <Grid item xs={12} sm={6} md={3} key={idx} sx={{ mt: { xs: 3, md: 6 } }}>
            <Box sx={{ position: 'relative' }}>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={cardVariants}
                transition={{ duration: 0.5, delay: idx * 0.2 }}
              >
                <GlassCard>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={avatarVariants}
                  >
                    <StyledAvatar src={leader.avatar} alt={leader.name} />
                  </motion.div>
                  <CardContent sx={{ mt: 6 }}>
                    <Typography variant="h6" fontWeight="bold">
                      {leader.name}
                    </Typography>
                    <RoleTypography color={leader.roleColor}>
                      <RoleIcon icon={leader.icon} color={leader.roleColor} />
                      {leader.role}
                    </RoleTypography>
                    <DescriptionText>{leader.description}</DescriptionText>
                  </CardContent>
                </GlassCard>
              </motion.div>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={6} justifyContent="center" sx={{ mt: 6 }}>
        {owners.map((owner, idx) => (
          <Grid item xs={12} sm={6} md={3} key={idx} sx={{ mt: { xs: 3, md: 6 } }}>
            <Box sx={{ position: 'relative' }}>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={cardVariants}
                transition={{ duration: 0.5, delay: idx * 0.2 }}
              >
                <GlassCard>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={avatarVariants}
                  >
                    <StyledAvatar src={owner.avatar} alt={owner.name} />
                  </motion.div>
                  <CardContent sx={{ mt: 6 }}>
                    <Typography variant="h6" fontWeight="bold">
                      {owner.name}
                    </Typography>
                    <RoleTypography color={owner.roleColor}>
                      <RoleIcon icon={owner.icon} color={owner.roleColor} />
                      {owner.role}
                    </RoleTypography>
                    {owner.secondRole && (
                      <RoleTypography color="#e68a00">
                        <RoleIcon icon={owner.secondIcon} color="#e68a00" />
                        {owner.secondRole}
                      </RoleTypography>
                    )}
                    <DescriptionText>{owner.description}</DescriptionText>
                  </CardContent>
                </GlassCard>
              </motion.div>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Team;
