import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMemory, faMicrochip, faHardDrive, faCloud, faHandshake, faHouse, faCode } from '@fortawesome/free-solid-svg-icons'; 
import StarsIcon from '@mui/icons-material/Stars';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { motion } from 'framer-motion';

interface Plan {
  title: string;
  price: string;
  ram: string;
  swap?: string;
  features: string[];
  popular?: boolean;
  oldPrice?: string; 
  discountLabel?: string; 
}

interface OfferCategory {
  category: string;
  plans: Plan[];
}

export const Cennik: FC = () => {
  const offers: OfferCategory[] = [
    {
      category: 'Boty Discord',
      plans: [
        { 
          title: 'Trial (24h)', 
          price: 'FREE', 
          ram: '128MB RAM', 
          swap: '64MB Swap',
          features: ['10% vCPU', '512 MB DISK', '0 BACKUPS'] 
        },
        { 
          title: 'Small', 
          price: '4.99 PLN', 
          ram: '1GB RAM', 
          swap: '512MB Swap',
          features: ['50% vCPU', '1 GB DISK', '3 BACKUPS'] 
        },
        { 
          title: 'Medium', 
          price: '9.99 PLN', 
          ram: '1,5GB RAM', 
          swap: '512MB Swap',
          features: ['75% vCPU', '2 GB DISK', '3 BACKUPS'], 
          popular: true 
        },
        { 
          title: 'Big', 
          price: '14.99 PLN', 
          ram: '3GB RAM', 
          swap: '1,5GB Swap',
          features: ['100% vCPU', '5 GB DISK', '3 BACKUPS'] 
        },
      ],
    },
    {
      category: 'Bazy Danych',
      plans: [
        { 
          title: 'MariaDB 10.3', 
          price: '6.99 PLN', 
          ram: '2GB RAM', 
          swap: '1GB Swap',
          features: ['50% vCPU', '5 GB DISK', '3 BACKUPS'] 
        },
        { 
          title: 'MongoDB 7', 
          price: '6.99 PLN', 
          ram: '2GB RAM', 
          swap: '1GB Swap',
          features: ['50% vCPU', '5 GB DISK', '3 BACKUPS'] 
        },
        { 
          title: 'Postgres 16', 
          price: '6.99 PLN', 
          ram: '2GB RAM', 
          swap: '1GB Swap',
          features: ['50% vCPU', '5 GB DISK', '3 BACKUPS'] 
        },
        { 
          title: 'RethinkDB', 
          price: '6.99 PLN', 
          ram: '2GB RAM', 
          swap: '1GB Swap',
          features: ['50% vCPU', '5 GB DISK', '3 BACKUPS'] 
        },
      ],
    },
    {
      category: 'Webhost',
      plans: [
        {
          title: 'Small',
          price: '6.99 PLN', 
          ram: '1GB RAM',
          swap: '512MB Swap',
          features: ['75% vCPU', '4 GB DISK', '3 BACKUPS', 'DOMENA NA ŻĄDANIE', 'NAJNOWSZE PHP I WORDPRESS'],
        },
      ],
    },
    {
      category: 'Serwery TeamSpeak 3',
      plans: [
        {
          title: 'TS3',
          oldPrice: '5.99 PLN', 
          price: '4.19 PLN', 
          ram: '2GB RAM',
          swap: '1GB Swap',
          features: ['75% vCPU', '3 GB DISK', '3 BACKUPS'],
          discountLabel: '-30% Zniżka', 
        },
      ],
    },
  ];

  const getIcon = (feature: string) => {
    if (feature.includes('RAM')) return <FontAwesomeIcon icon={faMemory} color="#1e90ff" />;
    if (feature.includes('vCPU')) return <FontAwesomeIcon icon={faMicrochip} color="#1e90ff" />;
    if (feature.includes('DISK')) return <FontAwesomeIcon icon={faHardDrive} color="#1e90ff" />;
    if (feature.includes('BACKUPS')) return <FontAwesomeIcon icon={faCloud} color="#1e90ff" />;
    if (feature.includes('DOMENA')) return <FontAwesomeIcon icon={faHouse} color="#1e90ff" />;
    if (feature.includes('PHP')) return <FontAwesomeIcon icon={faCode} color="#1e90ff" />;
    return null;
  };

  return (
    <Box sx={{ bgcolor: 'white', py: 10 }}>
      <Box maxWidth="lg" mx="auto">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography
            variant="h4"
            align="center"
            fontWeight="bold"
            color="text.primary"
            gutterBottom
            sx={{ mb: 6 }}
          >
            Nasz{' '}
            <Typography component="span" color="primary" sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: '2rem' }}>
              Cennik
            </Typography>
          </Typography>
        </motion.div>

        {offers.map((offerCategory, idx) => (
          <Box key={idx} sx={{ mb: 8 }}>
            <Typography
              variant="h5"
              align="left"
              fontWeight="bold"
              sx={{ color: 'primary.main', mb: 4 }}
            >
              {offerCategory.category}
            </Typography>
            <Grid container spacing={6} justifyContent="center">
              {offerCategory.plans.map((plan, idx) => (
                <Grid item xs={12} sm={6} md={4} key={idx}>
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: idx * 0.2 }}
                  >
                    <Card
                      sx={{
                        borderRadius: '16px',
                        boxShadow: plan?.discountLabel ? '0 0 10px #28a745, 0 0 20px #28a745, 0 0 30px #28a745' : plan?.popular ? '0 0 10px #1e90ff, 0 0 20px #1e90ff, 0 0 30px #1e90ff' : '0 4px 10px rgba(0, 0, 0, 0.1)',
                        transform: plan?.popular || plan?.discountLabel ? 'scale(1.05)' : 'scale(1)',
                        transition: 'transform 0.3s ease-in-out',
                        position: 'relative',
                        minHeight: '380px',
                        padding: '30px',
                      }}
                    >

                      {/* Discount Label with Handshake Icon */}
                      {plan.discountLabel && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '-5px',
                            right: '15px',
                            bgcolor: '#28a745',
                            color: 'white',
                            px: 2.5,
                            py: 1,
                            borderRadius: '0 0 8px 8px',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            alignItems: 'center', 
                          }}
                        >
                          <FontAwesomeIcon icon={faHandshake} style={{ marginRight: '5px', fontSize: '14px' }} />
                          <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                            {plan.discountLabel}
                          </Typography>
                        </Box>
                      )}

                      {/* Popular Label */}
                      {plan?.popular && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '-5px',
                            right: '15px',
                            bgcolor: '#1e90ff',
                            color: 'white',
                            px: 2.5,
                            py: 1,
                            borderRadius: '0 0 8px 8px',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                          }}
                        >
                          <StarsIcon sx={{ mr: 0.5, fontSize: '18px' }} />
                          <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                            Popularne
                          </Typography>
                        </Box>
                      )}

                      <CardContent>
                        <Typography variant="h6" color="text.primary" align="center" gutterBottom sx={{ mb: 2 }}>
                          {plan.title}
                        </Typography>

                        {plan.oldPrice && (
                          <Typography
                            variant="body2"
                            color="error"
                            align="center"
                            sx={{ textDecoration: 'line-through', fontSize: '1rem', color: '#ff1744' }}
                          >
                            {plan.oldPrice}
                          </Typography>
                        )}

                        <Typography variant="h5" color="primary" align="center" gutterBottom sx={{ mb: 2 }}>
                          {plan.price}
                        </Typography>

                        {/* Display RAM with Tooltip */}
                        <Box display="flex" alignItems="center" justifyContent="center" my={2}>
                          {getIcon('RAM')}
                          <Typography variant="body2" color="text.secondary" align="center" sx={{ ml: 1 }}>
                            {plan.ram}
                          </Typography>
                          {plan.swap && (
                            <Tooltip title={`${plan.swap}`} arrow>
                              <Box
                                sx={{
                                  ml: 1,
                                  width: '20px',
                                  height: '20px',
                                  borderRadius: '50%',
                                  backgroundColor: '#e0e0e0',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                <HelpOutlineIcon sx={{ fontSize: '16px', color: 'gray' }} />
                              </Box>
                            </Tooltip>
                          )}
                        </Box>

                        <Box my={2}>
                          <ul style={{ padding: 0, listStyleType: 'none' }}>
                            {plan.features.map((feature, i) => (
                              <li key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '12px' }}>
                                {getIcon(feature)}
                                <Typography variant="body2" color="text.secondary" align="center" sx={{ ml: 1 }}>
                                  {feature}
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </Box>

                        <Box display="flex" justifyContent="center">
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ borderRadius: '30px', mt: 3 }}
                            onClick={() => window.location.href = 'https://dc.blackhost.pl'}
                          >
                            Zakup {plan.title}
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
