import { FC, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import logo from '../img/logo.png';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { motion } from 'framer-motion';

export const Header: FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar position="fixed" sx={{ bgcolor: 'rgba(255, 255, 255, 0.4)', backdropFilter: 'blur(5px)', boxShadow: 'none' }}>
        <Toolbar>
          <motion.div
            whileHover={{ scale: 1.2, rotate: 90 }}
            transition={{ duration: 0.5 }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ display: { xs: 'block', md: 'none' }, color: 'blue' }} 
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </motion.div>
          <img src={logo} alt="BlackHost Logo" style={{ width: '50px', marginRight: '10px' }} />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: 'primary.main', fontWeight: 'bold', fontSize: { xs: '1rem', md: '1.5rem' } }}
          >
            BlackHost
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Button color="primary" href="/" sx={{ mr: 2 }}>
              Strona główna
            </Button>
            <Button color="primary" href="/cennik" sx={{ mr: 2 }}>
              Oferta
            </Button>
            <Button color="primary" href="/onas" sx={{ mr: 2 }}>
              O Nas
            </Button>
            <Button color="primary" href="https://dc.blackhost.pl" sx={{ mr: 2 }}>
              Kontakt
            </Button>
            <Button color="primary" href="/team" sx={{ mr: 2 }}>
              Nasz team
            </Button>
            <Button
              variant="outlined"
              color="primary"
              href="https://panel.blackhost.pl/"
              sx={{
                borderRadius: '30px',
                borderColor: '#1a73e8',
                '&:hover': { backgroundColor: 'rgba(0, 123, 255, 0.1)', transition: '0.3s' },
              }}
            >
              Panel Klienta
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List sx={{ width: 250 }} onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <ListItem component="a" href="/">
            <ListItemText primary="Strona główna" />
          </ListItem>
          <ListItem component="a" href="/cennik">
            <ListItemText primary="Oferta" />
          </ListItem>
          <ListItem component="a" href="/onas">
            <ListItemText primary="O Nas" />
          </ListItem>
          <ListItem component="a" href="https://dc.blackhost.pl">
            <ListItemText primary="Kontakt" />
          </ListItem>
          <ListItem component="a" href="/team">
            <ListItemText primary="Nasz Team" />
          </ListItem>
          <ListItem component="a" href="https://panel.blackhost.pl/">
            <ListItemText primary="Panel Klienta" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};
