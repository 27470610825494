import { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { motion } from 'framer-motion';


import yol_yol from '../avatars/Discord_logo.png';
import machendus from '../avatars/machendus.png';
import dekriss1337 from '../avatars/Discord_logo.png';
import lxgend_69 from '../avatars/_lxgend_69.png';
import vi_vio from '../avatars/vi_vio.png';
import pegaz from '../avatars/Discord_logo.png';
import vexidev from '../avatars/vexidev.png';
import demobywatel from '../avatars/demobywatel.png';
import ziimnyy from '../avatars/ziimnyy.png';
import ikra_gif from '../avatars/__ikra__.gif';

const testimonials = [
  {
    name: 'ziimnyy',
    text: 'wszystko legit według umowy polecam',
    rating: 5,
    avatar: ziimnyy,
  },
  {
    name: 'demobywatel',
    text: 'Najlepszy servis do hostowania z jakim kiedykolwiek przyszło mi współpracować',
    rating: 5,
    avatar: demobywatel,
  },
  {
    name: '.vexidev',
    text: 'No to tak nie będę owijać w bawełnę najlepszy hosting botów discord, najlepsze ceny ( lepszych nie znazałem ) super oferty, Pomocny Owner ( vi_vio ❤️ ) Jak i Project Liderzy, wspaniała i natychmiastowa pomoc, umiejąc pomóc już w 2 min a na innych hostingach to by się czekało 2 dni. Ogólnie super kadra jak i sam hosting 😉',
    rating: 5,
    avatar: vexidev,
  },
  {
    name: '.pegaz.',
    text: 'Mili właściciele i cały team. Kupiłem hosting bota i strony www. Mam nadzieję że przyniosą mi dobry start mojego projektu www za dobrą cenę. Ogarnięci są i dużo pomagają. Ludzie z pasją',
    rating: 5,
    avatar: pegaz,
  },
  {
    name: 'vi_vio',
    text: 'Najlepszy hosting botów jaki znam. Przystepne ceny. Obsługa klienta na najwyższym poziomie. Mega polecam 😊',
    rating: 5,
    avatar: vi_vio,
  },
  {
    name: '__ikra__',
    text: 'zjebiste typy mega pomocni',
    rating: 5,
    avatar: ikra_gif, 
  },
  {
    name: '_lxgend_69',
    text: 'Bardzo fajna obsługa. Miły właściciel. Niskie ceny. Wszystko czego potrzebujesz znajdziesz tutuj Kupujecie dopóki możecie 😁',
    rating: 5,
    avatar: lxgend_69,
  },
  {
    name: 'dekriss1337',
    text: 'Serdecznie polecam, miła i szybka obsługa klienta. Właściciel bardzo uprzejmy, a hosting nie zawodny. Polecam serdecznie każdemu',
    rating: 5,
    avatar: dekriss1337,
  },
  {
    name: 'machendus',
    text: 'sigma hosting stoję na nim',
    rating: 5,
    avatar: machendus,
  },
  {
    name: 'yol_yol',
    text: 'Polecam bardzo dobry hosting',
    rating: 5,
    avatar: yol_yol,
  },
];

export const Testimonials: FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(1); 

  const handleNext = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 8000); 
    return () => clearInterval(interval);
  }, []);

  const renderStars = (rating: number) => {
    return (
      <Box display="flex" justifyContent="center" sx={{ mb: 2 }}>
        {Array.from({ length: 5 }, (_, i) =>
          i < rating ? (
            <StarIcon key={i} sx={{ color: '#ffc107' }} />
          ) : (
            <StarBorderIcon key={i} sx={{ color: '#ffc107' }} />
          )
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ py: 8, bgcolor: 'gray.100' }}>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography variant="h4" align="center" fontWeight="bold" gutterBottom>
          Opinie naszych{' '}
          <Typography component="span" color="primary" sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: '2rem' }}>
            klientów
          </Typography>
        </Typography>
      </motion.div>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4, position: 'relative' }}>
        <Button
          onClick={handlePrev}
          sx={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)' }}
        >
          <ArrowBackIosNewIcon />
        </Button>
        <motion.div
          key={currentIndex}
          initial={{ x: direction === 1 ? 100 : -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: direction === 1 ? -100 : 100, opacity: 0 }}
          transition={{ duration: 0.6, ease: 'easeInOut' }}
        >
          <Paper
            elevation={3}
            sx={{
              width: { xs: '90%', md: '80%' },
              maxWidth: '600px',
              p: { xs: 3, md: 5 },
              borderRadius: '16px',
              textAlign: 'center',
              mx: 'auto',
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
              minHeight: '300px', 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Avatar
              alt={testimonials[currentIndex].name}
              src={testimonials[currentIndex].avatar}
              sx={{ width: 80, height: 80, mb: 2, mx: 'auto' }}
            />
            {renderStars(testimonials[currentIndex].rating)}
            <Typography variant="body1" color="text.primary" sx={{ fontSize: '1.2rem', mb: 2 }}>
              {testimonials[currentIndex].text}
            </Typography>
            <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
              {testimonials[currentIndex].name}
            </Typography>
          </Paper>
        </motion.div>
        <Button
          onClick={handleNext}
          sx={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
        >
          <ArrowForwardIosIcon />
        </Button>
      </Box>
    </Box>
  );
};
