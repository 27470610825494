import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faGlobe, faMaximize } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

export const WhyUs: FC = () => {
  const reasons = [
    { icon: faChartSimple, title: 'Wydajne serwery', description: 'Nasze serwery zapewniają wyjątkową wydajność i niezawodność.' },
    { icon: faGlobe, title: 'Niskie opóźnienia', description: 'Nasze serwery oferują minimalne opóźnienia, co przekłada się na lepszą jakość usług.' },
    { icon: faMaximize, title: 'Skalowalność', description: 'Nasze rozwiązania są elastyczne i łatwo skalowalne.' },
  ];

  return (
    <Box sx={{ bgcolor: 'gray.100', py: { xs: 4, md: 8 } }}>
      <Box maxWidth="lg" mx="auto">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography variant="h4" align="center" fontWeight="bold" color="text.primary" gutterBottom sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}>
            Dlaczego{' '}
            <Typography component="span" sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: { xs: '1.5rem', md: '2rem' } }}>
              my?
            </Typography>
          </Typography>
        </motion.div>
        <Grid container spacing={6} justifyContent="center">
          {reasons.map((reason, idx) => (
            <Grid item xs={12} sm={6} md={4} key={idx}>
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, delay: idx * 0.2 }}
              >
                <Card sx={{ borderRadius: '20px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FontAwesomeIcon icon={reason.icon} size="3x" color="#1976d2" />
                    </Box>
                    <Typography variant="h6" color="text.primary" align="center" gutterBottom>{reason.title}</Typography>
                    <Typography variant="body1" color="text.secondary" align="center">{reason.description}</Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
