import { FC, useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { WhyUs } from '../components/WhyUs';
import { Offer } from '../components/Offer';
import { ServerTerminal } from '../components/ServerTerminal';
import { Testimonials } from '../components/Testimonials';
import { motion } from 'framer-motion';

export const Home: FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
        setOpen(true); 
      } else {
        setIsMobile(false);
        setOpen(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize); 
    return () => window.removeEventListener('resize', handleResize); 
  }, []);

  const handleClose = () => {
    setOpen(false); 
  };

  return (
    <div>
      <Snackbar open={open} onClose={handleClose} autoHideDuration={8000}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          Strona nie jest w pełni przystosowana do urządzeń mobilnych. Zalecamy korzystanie z komputera!
        </Alert>
      </Snackbar>

      <main style={{ paddingTop: '100px' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: 'easeOut' }}
              >
                <Typography
                  variant="h3"
                  gutterBottom
                  sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: '2rem', md: '2.5rem' },
                    lineHeight: '1.2',
                    textAlign: { xs: 'center', md: 'left' },
                  }}
                >
                  Zapomnij o kosztach, skup się na{' '}
                  <Typography
                    component="span"
                    sx={{
                      color: 'primary.main',
                      fontWeight: 'bold',
                      fontSize: { xs: '2rem', md: '2.5rem' },
                    }}
                  >
                    społeczności
                  </Typography>
                </Typography>
                <Typography
                  variant="h5"
                  color="text.secondary"
                  gutterBottom
                  sx={{ textAlign: { xs: 'center', md: 'left' } }}
                >
                  Hosting botów Discord już od 4.99 PLN
                </Typography>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    href="https://dc.blackhost.pl"
                    size="large"
                    sx={{ mt: 4, borderRadius: '30px' }}
                  >
                    DISCORD
                  </Button>
                </motion.div>
              </motion.div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              <ServerTerminal />
            </Grid>
          </Grid>
        </Container>

        <WhyUs />

        <Offer />

        <Testimonials />
      </main>
    </div>
  );
};

export default Home;
