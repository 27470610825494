import { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { motion } from 'framer-motion';

const commands = [
  'root@blackhost:~$ systemctl status blackhost',
  'Up and running!',
  'Up and running!',
  'root@blackhost:~$ ping',
  'Ping: 40ms',
];

export const ServerTerminal: FC = () => {
  const [displayedCommands, setDisplayedCommands] = useState<string[]>([]);

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < commands.length) {
        setDisplayedCommands((prev) => [...prev, commands[index]]);
        index++;
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          bgcolor: 'rgba(220, 220, 220, 0.7)',
          color: '#1b64ff',
          height: '200px',
          overflow: 'auto',
          borderRadius: '16px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
          display: { xs: 'none', md: 'block' }, 
        }}
      >
        {displayedCommands.map((cmd, idx) => (
          <Typography key={idx} variant="h6" component="div" sx={{ fontSize: '1.2rem' }}>
            {cmd}
          </Typography>
        ))}
      </Paper>
    </motion.div>
  );
};
